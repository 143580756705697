import revive_payload_client_1PcZqsTw3M from "/builds/robustastudio/mazaya/pancho-v3/node_modules/.pnpm/nuxt@3.11.2_@types+node@20.5.7_@unocss+reset@0.59.4_eslint@8.57.0_floating-vue@5.2.2_rollup@4_ver75k3fqjaokkggbkd5pg2zc4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_LdPgz9MAWf from "/builds/robustastudio/mazaya/pancho-v3/node_modules/.pnpm/nuxt@3.11.2_@types+node@20.5.7_@unocss+reset@0.59.4_eslint@8.57.0_floating-vue@5.2.2_rollup@4_ver75k3fqjaokkggbkd5pg2zc4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_i7zZDcysVM from "/builds/robustastudio/mazaya/pancho-v3/node_modules/.pnpm/nuxt@3.11.2_@types+node@20.5.7_@unocss+reset@0.59.4_eslint@8.57.0_floating-vue@5.2.2_rollup@4_ver75k3fqjaokkggbkd5pg2zc4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_GADgU85qqb from "/builds/robustastudio/mazaya/pancho-v3/node_modules/.pnpm/nuxt@3.11.2_@types+node@20.5.7_@unocss+reset@0.59.4_eslint@8.57.0_floating-vue@5.2.2_rollup@4_ver75k3fqjaokkggbkd5pg2zc4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_EjVlu54LWZ from "/builds/robustastudio/mazaya/pancho-v3/node_modules/.pnpm/nuxt@3.11.2_@types+node@20.5.7_@unocss+reset@0.59.4_eslint@8.57.0_floating-vue@5.2.2_rollup@4_ver75k3fqjaokkggbkd5pg2zc4/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/builds/robustastudio/mazaya/pancho-v3/.nuxt/components.plugin.mjs";
import prefetch_client_TBCPargxqi from "/builds/robustastudio/mazaya/pancho-v3/node_modules/.pnpm/nuxt@3.11.2_@types+node@20.5.7_@unocss+reset@0.59.4_eslint@8.57.0_floating-vue@5.2.2_rollup@4_ver75k3fqjaokkggbkd5pg2zc4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_icons_5WqyPyDF3d from "/builds/robustastudio/mazaya/pancho-v3/node_modules/.pnpm/@vite-pwa+nuxt@0.6.0_@vite-pwa+assets-generator@0.2.4_rollup@4.16.4_vite@5.2.10_workbox-build@7.1.0_workbox-window@7.1.0/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa-icons.mjs";
import pwa_client_Ccx5ikkkNI from "/builds/robustastudio/mazaya/pancho-v3/node_modules/.pnpm/@vite-pwa+nuxt@0.6.0_@vite-pwa+assets-generator@0.2.4_rollup@4.16.4_vite@5.2.10_workbox-build@7.1.0_workbox-window@7.1.0/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import nuxt_plugin_8RSGfV6d8k from "/builds/robustastudio/mazaya/pancho-v3/node_modules/.pnpm/nuxt-delay-hydration@1.3.3_rollup@4.16.4/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.mjs";
import plugin_HNecdvMGq2 from "/builds/robustastudio/mazaya/pancho-v3/node_modules/.pnpm/nuxt-jsonld@2.0.8_typescript@5.4.5/node_modules/nuxt-jsonld/dist/runtime/plugin.js";
import gtm_client_1noR3i9wUe from "/builds/robustastudio/mazaya/pancho-v3/node_modules/.pnpm/@robustastudio+e-commerce@1.42.0_@unocss+reset@0.59.4_floating-vue@5.2.2_nuxt@3.11.2_rollup@4_n4l4lv2pnc432dxpayvtuksmy4/node_modules/@robustastudio/e-commerce/dist/runtime/module-plugins/gtm.client.mjs";
import ssr_caching_4oRkAlqSYD from "/builds/robustastudio/mazaya/pancho-v3/node_modules/.pnpm/@robustastudio+e-commerce@1.42.0_@unocss+reset@0.59.4_floating-vue@5.2.2_nuxt@3.11.2_rollup@4_n4l4lv2pnc432dxpayvtuksmy4/node_modules/@robustastudio/e-commerce/dist/runtime/module-plugins/ssr-caching.mjs";
import i18n_O6lb5XqPtY from "/builds/robustastudio/mazaya/pancho-v3/node_modules/.pnpm/@nuxtjs+i18n@8.1.1_rollup@4.16.4_vue@3.4.25/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_BGoE9aXy1J from "/builds/robustastudio/mazaya/pancho-v3/node_modules/.pnpm/nuxt@3.11.2_@types+node@20.5.7_@unocss+reset@0.59.4_eslint@8.57.0_floating-vue@5.2.2_rollup@4_ver75k3fqjaokkggbkd5pg2zc4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import css_global_vars_client_8R4IaJFvRf from "/builds/robustastudio/mazaya/pancho-v3/plugins/css-global-vars.client.ts";
import loading_GxQ2Z9Goxa from "/builds/robustastudio/mazaya/pancho-v3/plugins/loading.ts";
import purify_html_Hz9hnAad5c from "/builds/robustastudio/mazaya/pancho-v3/plugins/purify-html.ts";
import setup_0lDNjxjaWh from "/builds/robustastudio/mazaya/pancho-v3/plugins/setup.ts";
export default [
  revive_payload_client_1PcZqsTw3M,
  unhead_LdPgz9MAWf,
  router_i7zZDcysVM,
  payload_client_GADgU85qqb,
  check_outdated_build_client_EjVlu54LWZ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_TBCPargxqi,
  pwa_icons_5WqyPyDF3d,
  pwa_client_Ccx5ikkkNI,
  nuxt_plugin_8RSGfV6d8k,
  plugin_HNecdvMGq2,
  gtm_client_1noR3i9wUe,
  ssr_caching_4oRkAlqSYD,
  i18n_O6lb5XqPtY,
  chunk_reload_client_BGoE9aXy1J,
  css_global_vars_client_8R4IaJFvRf,
  loading_GxQ2Z9Goxa,
  purify_html_Hz9hnAad5c,
  setup_0lDNjxjaWh
]