import { default as indexoTDmuUcfKKMeta } from "/builds/robustastudio/mazaya/pancho-v3/pages/[...slug]/index.vue?macro=true";
import { default as aboutANci8QLWCeMeta } from "/builds/robustastudio/mazaya/pancho-v3/pages/about.vue?macro=true";
import { default as editXCCsCCKHTeMeta } from "/builds/robustastudio/mazaya/pancho-v3/pages/account/addresses/[id]/edit.vue?macro=true";
import { default as indexfmTd8vCV60Meta } from "/builds/robustastudio/mazaya/pancho-v3/pages/account/addresses/index.vue?macro=true";
import { default as newja6GBM3osfMeta } from "/builds/robustastudio/mazaya/pancho-v3/pages/account/addresses/new.vue?macro=true";
import { default as emailYnISMHfN7zMeta } from "/builds/robustastudio/mazaya/pancho-v3/pages/account/edit/email.vue?macro=true";
import { default as indexOK42HqobyCMeta } from "/builds/robustastudio/mazaya/pancho-v3/pages/account/edit/index.vue?macro=true";
import { default as password3x1uiD6tFlMeta } from "/builds/robustastudio/mazaya/pancho-v3/pages/account/edit/password.vue?macro=true";
import { default as indexmAGHTrRPRrMeta } from "/builds/robustastudio/mazaya/pancho-v3/pages/account/index.vue?macro=true";
import { default as _91id_93whgoC8kXUNMeta } from "/builds/robustastudio/mazaya/pancho-v3/pages/account/orders/[id].vue?macro=true";
import { default as indexpxyOzqPWaCMeta } from "/builds/robustastudio/mazaya/pancho-v3/pages/account/orders/index.vue?macro=true";
import { default as wishlistlcJ3wPRpEnMeta } from "/builds/robustastudio/mazaya/pancho-v3/pages/account/wishlist.vue?macro=true";
import { default as account3i741lVXocMeta } from "/builds/robustastudio/mazaya/pancho-v3/pages/account.vue?macro=true";
import { default as recovernHFi37FkiWMeta } from "/builds/robustastudio/mazaya/pancho-v3/pages/auth/recover.vue?macro=true";
import { default as _91slug_93btCz1tHoHtMeta } from "/builds/robustastudio/mazaya/pancho-v3/pages/brands/[slug].vue?macro=true";
import { default as indexwWXv9xIphfMeta } from "/builds/robustastudio/mazaya/pancho-v3/pages/brands/index.vue?macro=true";
import { default as cart3oxUfLgPWcMeta } from "/builds/robustastudio/mazaya/pancho-v3/pages/cart.vue?macro=true";
import { default as guest_45shippingJfdfAlI3MtMeta } from "/builds/robustastudio/mazaya/pancho-v3/pages/checkout/guest-shipping.vue?macro=true";
import { default as pay7gbg4iIORVMeta } from "/builds/robustastudio/mazaya/pancho-v3/pages/checkout/pay.vue?macro=true";
import { default as shippingamwTM6LIOtMeta } from "/builds/robustastudio/mazaya/pancho-v3/pages/checkout/shipping.vue?macro=true";
import { default as contactY5xGQFpLA1Meta } from "/builds/robustastudio/mazaya/pancho-v3/pages/contact.vue?macro=true";
import { default as dealsdl60iV8DcGMeta } from "/builds/robustastudio/mazaya/pancho-v3/pages/deals.vue?macro=true";
import { default as error0BBwsXbIzKMeta } from "/builds/robustastudio/mazaya/pancho-v3/pages/error.vue?macro=true";
import { default as _91slug_93AY90gW80cyMeta } from "/builds/robustastudio/mazaya/pancho-v3/pages/faqs/[slug].vue?macro=true";
import { default as index1XVpjyXhLBMeta } from "/builds/robustastudio/mazaya/pancho-v3/pages/faqs/index.vue?macro=true";
import { default as successC0zgbFZnmPMeta } from "/builds/robustastudio/mazaya/pancho-v3/pages/guest-order/[id]/success.vue?macro=true";
import { default as health_45checkTlkuK6TcmlMeta } from "/builds/robustastudio/mazaya/pancho-v3/pages/health-check.vue?macro=true";
import { default as indexBZf9Pq479YMeta } from "/builds/robustastudio/mazaya/pancho-v3/pages/index.vue?macro=true";
import { default as loginYiO4cy7hYZMeta } from "/builds/robustastudio/mazaya/pancho-v3/pages/login.vue?macro=true";
import { default as new_45inRtOgGgKEeNMeta } from "/builds/robustastudio/mazaya/pancho-v3/pages/new-in.vue?macro=true";
import { default as create_45reviewXArxgYVa0zMeta } from "/builds/robustastudio/mazaya/pancho-v3/pages/orders/[id]/create-review.vue?macro=true";
import { default as success8TZQ5BBNkkMeta } from "/builds/robustastudio/mazaya/pancho-v3/pages/orders/[id]/success.vue?macro=true";
import { default as privacy_45policy7FYRwNEmseMeta } from "/builds/robustastudio/mazaya/pancho-v3/pages/privacy-policy.vue?macro=true";
import { default as registerg6i5Q7lrNNMeta } from "/builds/robustastudio/mazaya/pancho-v3/pages/register.vue?macro=true";
import { default as _91name_93q9conYqD0MMeta } from "/builds/robustastudio/mazaya/pancho-v3/pages/search/[name].vue?macro=true";
import { default as searchC7zDP9mhQxMeta } from "/builds/robustastudio/mazaya/pancho-v3/pages/search.vue?macro=true";
import { default as terms_45and_45conditionsILtS7o3dXJMeta } from "/builds/robustastudio/mazaya/pancho-v3/pages/terms-and-conditions.vue?macro=true";
import { default as testDS5IArkatQMeta } from "/builds/robustastudio/mazaya/pancho-v3/pages/test.vue?macro=true";
export default [
  {
    name: indexoTDmuUcfKKMeta?.name ?? "slug___ar",
    path: indexoTDmuUcfKKMeta?.path ?? "/ar/:slug(.*)*",
    meta: indexoTDmuUcfKKMeta || {},
    alias: indexoTDmuUcfKKMeta?.alias || [],
    redirect: indexoTDmuUcfKKMeta?.redirect,
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/[...slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexoTDmuUcfKKMeta?.name ?? "slug___en",
    path: indexoTDmuUcfKKMeta?.path ?? "/en/:slug(.*)*",
    meta: indexoTDmuUcfKKMeta || {},
    alias: indexoTDmuUcfKKMeta?.alias || [],
    redirect: indexoTDmuUcfKKMeta?.redirect,
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/[...slug]/index.vue").then(m => m.default || m)
  },
  {
    name: aboutANci8QLWCeMeta?.name ?? "about___ar",
    path: aboutANci8QLWCeMeta?.path ?? "/ar/about",
    meta: aboutANci8QLWCeMeta || {},
    alias: aboutANci8QLWCeMeta?.alias || [],
    redirect: aboutANci8QLWCeMeta?.redirect,
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/about.vue").then(m => m.default || m)
  },
  {
    name: aboutANci8QLWCeMeta?.name ?? "about___en",
    path: aboutANci8QLWCeMeta?.path ?? "/en/about",
    meta: aboutANci8QLWCeMeta || {},
    alias: aboutANci8QLWCeMeta?.alias || [],
    redirect: aboutANci8QLWCeMeta?.redirect,
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/about.vue").then(m => m.default || m)
  },
  {
    name: account3i741lVXocMeta?.name ?? undefined,
    path: account3i741lVXocMeta?.path ?? "/ar/account",
    meta: account3i741lVXocMeta || {},
    alias: account3i741lVXocMeta?.alias || [],
    redirect: account3i741lVXocMeta?.redirect,
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/account.vue").then(m => m.default || m),
    children: [
  {
    name: editXCCsCCKHTeMeta?.name ?? "account-addresses-id-edit___ar",
    path: editXCCsCCKHTeMeta?.path ?? "addresses/:id()/edit",
    meta: editXCCsCCKHTeMeta || {},
    alias: editXCCsCCKHTeMeta?.alias || [],
    redirect: editXCCsCCKHTeMeta?.redirect,
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/account/addresses/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexfmTd8vCV60Meta?.name ?? "account-addresses___ar",
    path: indexfmTd8vCV60Meta?.path ?? "addresses",
    meta: indexfmTd8vCV60Meta || {},
    alias: indexfmTd8vCV60Meta?.alias || [],
    redirect: indexfmTd8vCV60Meta?.redirect,
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/account/addresses/index.vue").then(m => m.default || m)
  },
  {
    name: newja6GBM3osfMeta?.name ?? "account-addresses-new___ar",
    path: newja6GBM3osfMeta?.path ?? "addresses/new",
    meta: newja6GBM3osfMeta || {},
    alias: newja6GBM3osfMeta?.alias || [],
    redirect: newja6GBM3osfMeta?.redirect,
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/account/addresses/new.vue").then(m => m.default || m)
  },
  {
    name: emailYnISMHfN7zMeta?.name ?? "account-edit-email___ar",
    path: emailYnISMHfN7zMeta?.path ?? "edit/email",
    meta: emailYnISMHfN7zMeta || {},
    alias: emailYnISMHfN7zMeta?.alias || [],
    redirect: emailYnISMHfN7zMeta?.redirect,
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/account/edit/email.vue").then(m => m.default || m)
  },
  {
    name: indexOK42HqobyCMeta?.name ?? "account-edit___ar",
    path: indexOK42HqobyCMeta?.path ?? "edit",
    meta: indexOK42HqobyCMeta || {},
    alias: indexOK42HqobyCMeta?.alias || [],
    redirect: indexOK42HqobyCMeta?.redirect,
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/account/edit/index.vue").then(m => m.default || m)
  },
  {
    name: password3x1uiD6tFlMeta?.name ?? "account-edit-password___ar",
    path: password3x1uiD6tFlMeta?.path ?? "edit/password",
    meta: password3x1uiD6tFlMeta || {},
    alias: password3x1uiD6tFlMeta?.alias || [],
    redirect: password3x1uiD6tFlMeta?.redirect,
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/account/edit/password.vue").then(m => m.default || m)
  },
  {
    name: indexmAGHTrRPRrMeta?.name ?? "account___ar",
    path: indexmAGHTrRPRrMeta?.path ?? "",
    meta: indexmAGHTrRPRrMeta || {},
    alias: indexmAGHTrRPRrMeta?.alias || [],
    redirect: indexmAGHTrRPRrMeta?.redirect,
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93whgoC8kXUNMeta?.name ?? "account-orders-id___ar",
    path: _91id_93whgoC8kXUNMeta?.path ?? "orders/:id()",
    meta: _91id_93whgoC8kXUNMeta || {},
    alias: _91id_93whgoC8kXUNMeta?.alias || [],
    redirect: _91id_93whgoC8kXUNMeta?.redirect,
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/account/orders/[id].vue").then(m => m.default || m)
  },
  {
    name: indexpxyOzqPWaCMeta?.name ?? "account-orders___ar",
    path: indexpxyOzqPWaCMeta?.path ?? "orders",
    meta: indexpxyOzqPWaCMeta || {},
    alias: indexpxyOzqPWaCMeta?.alias || [],
    redirect: indexpxyOzqPWaCMeta?.redirect,
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/account/orders/index.vue").then(m => m.default || m)
  },
  {
    name: wishlistlcJ3wPRpEnMeta?.name ?? "account-wishlist___ar",
    path: wishlistlcJ3wPRpEnMeta?.path ?? "wishlist",
    meta: wishlistlcJ3wPRpEnMeta || {},
    alias: wishlistlcJ3wPRpEnMeta?.alias || [],
    redirect: wishlistlcJ3wPRpEnMeta?.redirect,
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/account/wishlist.vue").then(m => m.default || m)
  }
]
  },
  {
    name: account3i741lVXocMeta?.name ?? undefined,
    path: account3i741lVXocMeta?.path ?? "/en/account",
    meta: account3i741lVXocMeta || {},
    alias: account3i741lVXocMeta?.alias || [],
    redirect: account3i741lVXocMeta?.redirect,
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/account.vue").then(m => m.default || m),
    children: [
  {
    name: editXCCsCCKHTeMeta?.name ?? "account-addresses-id-edit___en",
    path: editXCCsCCKHTeMeta?.path ?? "addresses/:id()/edit",
    meta: editXCCsCCKHTeMeta || {},
    alias: editXCCsCCKHTeMeta?.alias || [],
    redirect: editXCCsCCKHTeMeta?.redirect,
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/account/addresses/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexfmTd8vCV60Meta?.name ?? "account-addresses___en",
    path: indexfmTd8vCV60Meta?.path ?? "addresses",
    meta: indexfmTd8vCV60Meta || {},
    alias: indexfmTd8vCV60Meta?.alias || [],
    redirect: indexfmTd8vCV60Meta?.redirect,
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/account/addresses/index.vue").then(m => m.default || m)
  },
  {
    name: newja6GBM3osfMeta?.name ?? "account-addresses-new___en",
    path: newja6GBM3osfMeta?.path ?? "addresses/new",
    meta: newja6GBM3osfMeta || {},
    alias: newja6GBM3osfMeta?.alias || [],
    redirect: newja6GBM3osfMeta?.redirect,
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/account/addresses/new.vue").then(m => m.default || m)
  },
  {
    name: emailYnISMHfN7zMeta?.name ?? "account-edit-email___en",
    path: emailYnISMHfN7zMeta?.path ?? "edit/email",
    meta: emailYnISMHfN7zMeta || {},
    alias: emailYnISMHfN7zMeta?.alias || [],
    redirect: emailYnISMHfN7zMeta?.redirect,
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/account/edit/email.vue").then(m => m.default || m)
  },
  {
    name: indexOK42HqobyCMeta?.name ?? "account-edit___en",
    path: indexOK42HqobyCMeta?.path ?? "edit",
    meta: indexOK42HqobyCMeta || {},
    alias: indexOK42HqobyCMeta?.alias || [],
    redirect: indexOK42HqobyCMeta?.redirect,
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/account/edit/index.vue").then(m => m.default || m)
  },
  {
    name: password3x1uiD6tFlMeta?.name ?? "account-edit-password___en",
    path: password3x1uiD6tFlMeta?.path ?? "edit/password",
    meta: password3x1uiD6tFlMeta || {},
    alias: password3x1uiD6tFlMeta?.alias || [],
    redirect: password3x1uiD6tFlMeta?.redirect,
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/account/edit/password.vue").then(m => m.default || m)
  },
  {
    name: indexmAGHTrRPRrMeta?.name ?? "account___en",
    path: indexmAGHTrRPRrMeta?.path ?? "",
    meta: indexmAGHTrRPRrMeta || {},
    alias: indexmAGHTrRPRrMeta?.alias || [],
    redirect: indexmAGHTrRPRrMeta?.redirect,
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93whgoC8kXUNMeta?.name ?? "account-orders-id___en",
    path: _91id_93whgoC8kXUNMeta?.path ?? "orders/:id()",
    meta: _91id_93whgoC8kXUNMeta || {},
    alias: _91id_93whgoC8kXUNMeta?.alias || [],
    redirect: _91id_93whgoC8kXUNMeta?.redirect,
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/account/orders/[id].vue").then(m => m.default || m)
  },
  {
    name: indexpxyOzqPWaCMeta?.name ?? "account-orders___en",
    path: indexpxyOzqPWaCMeta?.path ?? "orders",
    meta: indexpxyOzqPWaCMeta || {},
    alias: indexpxyOzqPWaCMeta?.alias || [],
    redirect: indexpxyOzqPWaCMeta?.redirect,
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/account/orders/index.vue").then(m => m.default || m)
  },
  {
    name: wishlistlcJ3wPRpEnMeta?.name ?? "account-wishlist___en",
    path: wishlistlcJ3wPRpEnMeta?.path ?? "wishlist",
    meta: wishlistlcJ3wPRpEnMeta || {},
    alias: wishlistlcJ3wPRpEnMeta?.alias || [],
    redirect: wishlistlcJ3wPRpEnMeta?.redirect,
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/account/wishlist.vue").then(m => m.default || m)
  }
]
  },
  {
    name: recovernHFi37FkiWMeta?.name ?? "auth-recover___ar",
    path: recovernHFi37FkiWMeta?.path ?? "/ar/auth/recover",
    meta: recovernHFi37FkiWMeta || {},
    alias: recovernHFi37FkiWMeta?.alias || [],
    redirect: recovernHFi37FkiWMeta?.redirect,
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/auth/recover.vue").then(m => m.default || m)
  },
  {
    name: recovernHFi37FkiWMeta?.name ?? "auth-recover___en",
    path: recovernHFi37FkiWMeta?.path ?? "/en/auth/recover",
    meta: recovernHFi37FkiWMeta || {},
    alias: recovernHFi37FkiWMeta?.alias || [],
    redirect: recovernHFi37FkiWMeta?.redirect,
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/auth/recover.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93btCz1tHoHtMeta?.name ?? "brands-slug___ar",
    path: _91slug_93btCz1tHoHtMeta?.path ?? "/ar/brands/:slug()",
    meta: _91slug_93btCz1tHoHtMeta || {},
    alias: _91slug_93btCz1tHoHtMeta?.alias || [],
    redirect: _91slug_93btCz1tHoHtMeta?.redirect,
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/brands/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93btCz1tHoHtMeta?.name ?? "brands-slug___en",
    path: _91slug_93btCz1tHoHtMeta?.path ?? "/en/brands/:slug()",
    meta: _91slug_93btCz1tHoHtMeta || {},
    alias: _91slug_93btCz1tHoHtMeta?.alias || [],
    redirect: _91slug_93btCz1tHoHtMeta?.redirect,
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/brands/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexwWXv9xIphfMeta?.name ?? "brands___ar",
    path: indexwWXv9xIphfMeta?.path ?? "/ar/brands",
    meta: indexwWXv9xIphfMeta || {},
    alias: indexwWXv9xIphfMeta?.alias || [],
    redirect: indexwWXv9xIphfMeta?.redirect,
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/brands/index.vue").then(m => m.default || m)
  },
  {
    name: indexwWXv9xIphfMeta?.name ?? "brands___en",
    path: indexwWXv9xIphfMeta?.path ?? "/en/brands",
    meta: indexwWXv9xIphfMeta || {},
    alias: indexwWXv9xIphfMeta?.alias || [],
    redirect: indexwWXv9xIphfMeta?.redirect,
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/brands/index.vue").then(m => m.default || m)
  },
  {
    name: cart3oxUfLgPWcMeta?.name ?? "cart___ar",
    path: cart3oxUfLgPWcMeta?.path ?? "/ar/cart",
    meta: cart3oxUfLgPWcMeta || {},
    alias: cart3oxUfLgPWcMeta?.alias || [],
    redirect: cart3oxUfLgPWcMeta?.redirect,
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/cart.vue").then(m => m.default || m)
  },
  {
    name: cart3oxUfLgPWcMeta?.name ?? "cart___en",
    path: cart3oxUfLgPWcMeta?.path ?? "/en/cart",
    meta: cart3oxUfLgPWcMeta || {},
    alias: cart3oxUfLgPWcMeta?.alias || [],
    redirect: cart3oxUfLgPWcMeta?.redirect,
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/cart.vue").then(m => m.default || m)
  },
  {
    name: guest_45shippingJfdfAlI3MtMeta?.name ?? "checkout-guest-shipping___ar",
    path: guest_45shippingJfdfAlI3MtMeta?.path ?? "/ar/checkout/guest-shipping",
    meta: guest_45shippingJfdfAlI3MtMeta || {},
    alias: guest_45shippingJfdfAlI3MtMeta?.alias || [],
    redirect: guest_45shippingJfdfAlI3MtMeta?.redirect,
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/checkout/guest-shipping.vue").then(m => m.default || m)
  },
  {
    name: guest_45shippingJfdfAlI3MtMeta?.name ?? "checkout-guest-shipping___en",
    path: guest_45shippingJfdfAlI3MtMeta?.path ?? "/en/checkout/guest-shipping",
    meta: guest_45shippingJfdfAlI3MtMeta || {},
    alias: guest_45shippingJfdfAlI3MtMeta?.alias || [],
    redirect: guest_45shippingJfdfAlI3MtMeta?.redirect,
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/checkout/guest-shipping.vue").then(m => m.default || m)
  },
  {
    name: pay7gbg4iIORVMeta?.name ?? "checkout-pay___ar",
    path: pay7gbg4iIORVMeta?.path ?? "/ar/checkout/pay",
    meta: pay7gbg4iIORVMeta || {},
    alias: pay7gbg4iIORVMeta?.alias || [],
    redirect: pay7gbg4iIORVMeta?.redirect,
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/checkout/pay.vue").then(m => m.default || m)
  },
  {
    name: pay7gbg4iIORVMeta?.name ?? "checkout-pay___en",
    path: pay7gbg4iIORVMeta?.path ?? "/en/checkout/pay",
    meta: pay7gbg4iIORVMeta || {},
    alias: pay7gbg4iIORVMeta?.alias || [],
    redirect: pay7gbg4iIORVMeta?.redirect,
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/checkout/pay.vue").then(m => m.default || m)
  },
  {
    name: shippingamwTM6LIOtMeta?.name ?? "checkout-shipping___ar",
    path: shippingamwTM6LIOtMeta?.path ?? "/ar/checkout/shipping",
    meta: shippingamwTM6LIOtMeta || {},
    alias: shippingamwTM6LIOtMeta?.alias || [],
    redirect: shippingamwTM6LIOtMeta?.redirect,
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/checkout/shipping.vue").then(m => m.default || m)
  },
  {
    name: shippingamwTM6LIOtMeta?.name ?? "checkout-shipping___en",
    path: shippingamwTM6LIOtMeta?.path ?? "/en/checkout/shipping",
    meta: shippingamwTM6LIOtMeta || {},
    alias: shippingamwTM6LIOtMeta?.alias || [],
    redirect: shippingamwTM6LIOtMeta?.redirect,
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/checkout/shipping.vue").then(m => m.default || m)
  },
  {
    name: contactY5xGQFpLA1Meta?.name ?? "contact___ar",
    path: contactY5xGQFpLA1Meta?.path ?? "/ar/contact",
    meta: contactY5xGQFpLA1Meta || {},
    alias: contactY5xGQFpLA1Meta?.alias || [],
    redirect: contactY5xGQFpLA1Meta?.redirect,
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: contactY5xGQFpLA1Meta?.name ?? "contact___en",
    path: contactY5xGQFpLA1Meta?.path ?? "/en/contact",
    meta: contactY5xGQFpLA1Meta || {},
    alias: contactY5xGQFpLA1Meta?.alias || [],
    redirect: contactY5xGQFpLA1Meta?.redirect,
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: dealsdl60iV8DcGMeta?.name ?? "deals___ar",
    path: dealsdl60iV8DcGMeta?.path ?? "/ar/deals",
    meta: dealsdl60iV8DcGMeta || {},
    alias: dealsdl60iV8DcGMeta?.alias || [],
    redirect: dealsdl60iV8DcGMeta?.redirect,
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/deals.vue").then(m => m.default || m)
  },
  {
    name: dealsdl60iV8DcGMeta?.name ?? "deals___en",
    path: dealsdl60iV8DcGMeta?.path ?? "/en/deals",
    meta: dealsdl60iV8DcGMeta || {},
    alias: dealsdl60iV8DcGMeta?.alias || [],
    redirect: dealsdl60iV8DcGMeta?.redirect,
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/deals.vue").then(m => m.default || m)
  },
  {
    name: error0BBwsXbIzKMeta?.name ?? "error___ar",
    path: error0BBwsXbIzKMeta?.path ?? "/ar/error",
    meta: error0BBwsXbIzKMeta || {},
    alias: error0BBwsXbIzKMeta?.alias || [],
    redirect: error0BBwsXbIzKMeta?.redirect,
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/error.vue").then(m => m.default || m)
  },
  {
    name: error0BBwsXbIzKMeta?.name ?? "error___en",
    path: error0BBwsXbIzKMeta?.path ?? "/en/error",
    meta: error0BBwsXbIzKMeta || {},
    alias: error0BBwsXbIzKMeta?.alias || [],
    redirect: error0BBwsXbIzKMeta?.redirect,
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/error.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93AY90gW80cyMeta?.name ?? "faqs-slug___ar",
    path: _91slug_93AY90gW80cyMeta?.path ?? "/ar/faqs/:slug()",
    meta: _91slug_93AY90gW80cyMeta || {},
    alias: _91slug_93AY90gW80cyMeta?.alias || [],
    redirect: _91slug_93AY90gW80cyMeta?.redirect,
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/faqs/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93AY90gW80cyMeta?.name ?? "faqs-slug___en",
    path: _91slug_93AY90gW80cyMeta?.path ?? "/en/faqs/:slug()",
    meta: _91slug_93AY90gW80cyMeta || {},
    alias: _91slug_93AY90gW80cyMeta?.alias || [],
    redirect: _91slug_93AY90gW80cyMeta?.redirect,
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/faqs/[slug].vue").then(m => m.default || m)
  },
  {
    name: index1XVpjyXhLBMeta?.name ?? "faqs___ar",
    path: index1XVpjyXhLBMeta?.path ?? "/ar/faqs",
    meta: index1XVpjyXhLBMeta || {},
    alias: index1XVpjyXhLBMeta?.alias || [],
    redirect: index1XVpjyXhLBMeta?.redirect,
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/faqs/index.vue").then(m => m.default || m)
  },
  {
    name: index1XVpjyXhLBMeta?.name ?? "faqs___en",
    path: index1XVpjyXhLBMeta?.path ?? "/en/faqs",
    meta: index1XVpjyXhLBMeta || {},
    alias: index1XVpjyXhLBMeta?.alias || [],
    redirect: index1XVpjyXhLBMeta?.redirect,
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/faqs/index.vue").then(m => m.default || m)
  },
  {
    name: successC0zgbFZnmPMeta?.name ?? "guest-order-id-success___ar",
    path: successC0zgbFZnmPMeta?.path ?? "/ar/guest-order/:id()/success",
    meta: successC0zgbFZnmPMeta || {},
    alias: successC0zgbFZnmPMeta?.alias || [],
    redirect: successC0zgbFZnmPMeta?.redirect,
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/guest-order/[id]/success.vue").then(m => m.default || m)
  },
  {
    name: successC0zgbFZnmPMeta?.name ?? "guest-order-id-success___en",
    path: successC0zgbFZnmPMeta?.path ?? "/en/guest-order/:id()/success",
    meta: successC0zgbFZnmPMeta || {},
    alias: successC0zgbFZnmPMeta?.alias || [],
    redirect: successC0zgbFZnmPMeta?.redirect,
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/guest-order/[id]/success.vue").then(m => m.default || m)
  },
  {
    name: health_45checkTlkuK6TcmlMeta?.name ?? "health-check___ar",
    path: health_45checkTlkuK6TcmlMeta?.path ?? "/ar/health-check",
    meta: health_45checkTlkuK6TcmlMeta || {},
    alias: health_45checkTlkuK6TcmlMeta?.alias || [],
    redirect: health_45checkTlkuK6TcmlMeta?.redirect,
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/health-check.vue").then(m => m.default || m)
  },
  {
    name: health_45checkTlkuK6TcmlMeta?.name ?? "health-check___en",
    path: health_45checkTlkuK6TcmlMeta?.path ?? "/en/health-check",
    meta: health_45checkTlkuK6TcmlMeta || {},
    alias: health_45checkTlkuK6TcmlMeta?.alias || [],
    redirect: health_45checkTlkuK6TcmlMeta?.redirect,
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/health-check.vue").then(m => m.default || m)
  },
  {
    name: indexBZf9Pq479YMeta?.name ?? "index___ar",
    path: indexBZf9Pq479YMeta?.path ?? "/ar",
    meta: indexBZf9Pq479YMeta || {},
    alias: indexBZf9Pq479YMeta?.alias || [],
    redirect: indexBZf9Pq479YMeta?.redirect,
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexBZf9Pq479YMeta?.name ?? "index___en",
    path: indexBZf9Pq479YMeta?.path ?? "/en",
    meta: indexBZf9Pq479YMeta || {},
    alias: indexBZf9Pq479YMeta?.alias || [],
    redirect: indexBZf9Pq479YMeta?.redirect,
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginYiO4cy7hYZMeta?.name ?? "login___ar",
    path: loginYiO4cy7hYZMeta?.path ?? "/ar/login",
    meta: loginYiO4cy7hYZMeta || {},
    alias: loginYiO4cy7hYZMeta?.alias || [],
    redirect: loginYiO4cy7hYZMeta?.redirect,
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginYiO4cy7hYZMeta?.name ?? "login___en",
    path: loginYiO4cy7hYZMeta?.path ?? "/en/login",
    meta: loginYiO4cy7hYZMeta || {},
    alias: loginYiO4cy7hYZMeta?.alias || [],
    redirect: loginYiO4cy7hYZMeta?.redirect,
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/login.vue").then(m => m.default || m)
  },
  {
    name: new_45inRtOgGgKEeNMeta?.name ?? "new-in___ar",
    path: new_45inRtOgGgKEeNMeta?.path ?? "/ar/new-in",
    meta: new_45inRtOgGgKEeNMeta || {},
    alias: new_45inRtOgGgKEeNMeta?.alias || [],
    redirect: new_45inRtOgGgKEeNMeta?.redirect,
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/new-in.vue").then(m => m.default || m)
  },
  {
    name: new_45inRtOgGgKEeNMeta?.name ?? "new-in___en",
    path: new_45inRtOgGgKEeNMeta?.path ?? "/en/new-in",
    meta: new_45inRtOgGgKEeNMeta || {},
    alias: new_45inRtOgGgKEeNMeta?.alias || [],
    redirect: new_45inRtOgGgKEeNMeta?.redirect,
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/new-in.vue").then(m => m.default || m)
  },
  {
    name: create_45reviewXArxgYVa0zMeta?.name ?? "orders-id-create-review___ar",
    path: create_45reviewXArxgYVa0zMeta?.path ?? "/ar/orders/:id()/create-review",
    meta: create_45reviewXArxgYVa0zMeta || {},
    alias: create_45reviewXArxgYVa0zMeta?.alias || [],
    redirect: create_45reviewXArxgYVa0zMeta?.redirect,
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/orders/[id]/create-review.vue").then(m => m.default || m)
  },
  {
    name: create_45reviewXArxgYVa0zMeta?.name ?? "orders-id-create-review___en",
    path: create_45reviewXArxgYVa0zMeta?.path ?? "/en/orders/:id()/create-review",
    meta: create_45reviewXArxgYVa0zMeta || {},
    alias: create_45reviewXArxgYVa0zMeta?.alias || [],
    redirect: create_45reviewXArxgYVa0zMeta?.redirect,
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/orders/[id]/create-review.vue").then(m => m.default || m)
  },
  {
    name: success8TZQ5BBNkkMeta?.name ?? "orders-id-success___ar",
    path: success8TZQ5BBNkkMeta?.path ?? "/ar/orders/:id()/success",
    meta: success8TZQ5BBNkkMeta || {},
    alias: success8TZQ5BBNkkMeta?.alias || [],
    redirect: success8TZQ5BBNkkMeta?.redirect,
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/orders/[id]/success.vue").then(m => m.default || m)
  },
  {
    name: success8TZQ5BBNkkMeta?.name ?? "orders-id-success___en",
    path: success8TZQ5BBNkkMeta?.path ?? "/en/orders/:id()/success",
    meta: success8TZQ5BBNkkMeta || {},
    alias: success8TZQ5BBNkkMeta?.alias || [],
    redirect: success8TZQ5BBNkkMeta?.redirect,
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/orders/[id]/success.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policy7FYRwNEmseMeta?.name ?? "privacy-policy___ar",
    path: privacy_45policy7FYRwNEmseMeta?.path ?? "/ar/privacy-policy",
    meta: privacy_45policy7FYRwNEmseMeta || {},
    alias: privacy_45policy7FYRwNEmseMeta?.alias || [],
    redirect: privacy_45policy7FYRwNEmseMeta?.redirect,
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policy7FYRwNEmseMeta?.name ?? "privacy-policy___en",
    path: privacy_45policy7FYRwNEmseMeta?.path ?? "/en/privacy-policy",
    meta: privacy_45policy7FYRwNEmseMeta || {},
    alias: privacy_45policy7FYRwNEmseMeta?.alias || [],
    redirect: privacy_45policy7FYRwNEmseMeta?.redirect,
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: registerg6i5Q7lrNNMeta?.name ?? "register___ar",
    path: registerg6i5Q7lrNNMeta?.path ?? "/ar/register",
    meta: registerg6i5Q7lrNNMeta || {},
    alias: registerg6i5Q7lrNNMeta?.alias || [],
    redirect: registerg6i5Q7lrNNMeta?.redirect,
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registerg6i5Q7lrNNMeta?.name ?? "register___en",
    path: registerg6i5Q7lrNNMeta?.path ?? "/en/register",
    meta: registerg6i5Q7lrNNMeta || {},
    alias: registerg6i5Q7lrNNMeta?.alias || [],
    redirect: registerg6i5Q7lrNNMeta?.redirect,
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/register.vue").then(m => m.default || m)
  },
  {
    name: searchC7zDP9mhQxMeta?.name ?? "search___ar",
    path: searchC7zDP9mhQxMeta?.path ?? "/ar/search",
    meta: searchC7zDP9mhQxMeta || {},
    alias: searchC7zDP9mhQxMeta?.alias || [],
    redirect: searchC7zDP9mhQxMeta?.redirect,
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/search.vue").then(m => m.default || m),
    children: [
  {
    name: _91name_93q9conYqD0MMeta?.name ?? "search-name___ar",
    path: _91name_93q9conYqD0MMeta?.path ?? ":name()",
    meta: _91name_93q9conYqD0MMeta || {},
    alias: _91name_93q9conYqD0MMeta?.alias || [],
    redirect: _91name_93q9conYqD0MMeta?.redirect,
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/search/[name].vue").then(m => m.default || m)
  }
]
  },
  {
    name: searchC7zDP9mhQxMeta?.name ?? "search___en",
    path: searchC7zDP9mhQxMeta?.path ?? "/en/search",
    meta: searchC7zDP9mhQxMeta || {},
    alias: searchC7zDP9mhQxMeta?.alias || [],
    redirect: searchC7zDP9mhQxMeta?.redirect,
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/search.vue").then(m => m.default || m),
    children: [
  {
    name: _91name_93q9conYqD0MMeta?.name ?? "search-name___en",
    path: _91name_93q9conYqD0MMeta?.path ?? ":name()",
    meta: _91name_93q9conYqD0MMeta || {},
    alias: _91name_93q9conYqD0MMeta?.alias || [],
    redirect: _91name_93q9conYqD0MMeta?.redirect,
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/search/[name].vue").then(m => m.default || m)
  }
]
  },
  {
    name: terms_45and_45conditionsILtS7o3dXJMeta?.name ?? "terms-and-conditions___ar",
    path: terms_45and_45conditionsILtS7o3dXJMeta?.path ?? "/ar/terms-and-conditions",
    meta: terms_45and_45conditionsILtS7o3dXJMeta || {},
    alias: terms_45and_45conditionsILtS7o3dXJMeta?.alias || [],
    redirect: terms_45and_45conditionsILtS7o3dXJMeta?.redirect,
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/terms-and-conditions.vue").then(m => m.default || m)
  },
  {
    name: terms_45and_45conditionsILtS7o3dXJMeta?.name ?? "terms-and-conditions___en",
    path: terms_45and_45conditionsILtS7o3dXJMeta?.path ?? "/en/terms-and-conditions",
    meta: terms_45and_45conditionsILtS7o3dXJMeta || {},
    alias: terms_45and_45conditionsILtS7o3dXJMeta?.alias || [],
    redirect: terms_45and_45conditionsILtS7o3dXJMeta?.redirect,
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/terms-and-conditions.vue").then(m => m.default || m)
  },
  {
    name: testDS5IArkatQMeta?.name ?? "test___ar",
    path: testDS5IArkatQMeta?.path ?? "/ar/test",
    meta: testDS5IArkatQMeta || {},
    alias: testDS5IArkatQMeta?.alias || [],
    redirect: testDS5IArkatQMeta?.redirect,
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/test.vue").then(m => m.default || m)
  },
  {
    name: testDS5IArkatQMeta?.name ?? "test___en",
    path: testDS5IArkatQMeta?.path ?? "/en/test",
    meta: testDS5IArkatQMeta || {},
    alias: testDS5IArkatQMeta?.alias || [],
    redirect: testDS5IArkatQMeta?.redirect,
    component: () => import("/builds/robustastudio/mazaya/pancho-v3/pages/test.vue").then(m => m.default || m)
  }
]